import Toast from '@/features/enterprise/components/Toast';
import OptionIntegracao from '@/types/OptionIntegracao';
import React, {
  useContext,
  useState,
  createContext,
  ReactNode,
  useLayoutEffect,
  useCallback,
  useEffect,
} from 'react';
import { Slide, toast } from 'react-toastify';
import { FhirArtefatoType, GeneralStatusType } from '../../models/types';
import IntegracaoService from '../../services/IntegracaoService';

interface IntegrationState {
  generalStatus: GeneralStatusType;
  examesStatus: GeneralStatusType;
  medicamentosStatus: GeneralStatusType;
  encaminhamentosStatus: GeneralStatusType;
  orientacoesStatus: GeneralStatusType;
  fotoceuticosStatus: GeneralStatusType;
}

interface IntegrationItems {
  selectedExames: OptionIntegracao[];
  selectedMedicamentos: OptionIntegracao[];
  selectedEncaminhamentos: OptionIntegracao[];
  selectedOrientacoes: OptionIntegracao[];
  selectedFotoceuticos: OptionIntegracao[];
}

interface IntegrationDispatchType {
  updateExamesStatus: (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => void;
  updateMedicamentosStatus: (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => void;
  updateEncaminhamentosStatus: (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => void;
  updateOrientacoesStatus: (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => void;
  updateFotoceuticosStatus: (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => void;
  dispatchSolicitarRecursos: (
    passoExecutadoId: number,
  ) => Promise<FhirArtefatoType[] | null>;
  dispatchCancelarRecursos: () => void;
}

type IntegrationContextData = [
  IntegrationState,
  IntegrationItems,
  IntegrationDispatchType,
];

interface IntegrationProviderProps {
  children: ReactNode;
}

const initialState: IntegrationState = {
  generalStatus: 'none_selected',
  examesStatus: 'none_selected',
  medicamentosStatus: 'none_selected',
  encaminhamentosStatus: 'none_selected',
  orientacoesStatus: 'none_selected',
  fotoceuticosStatus: 'none_selected',
};

const initialItems: IntegrationItems = {
  selectedExames: [],
  selectedMedicamentos: [],
  selectedEncaminhamentos: [],
  selectedOrientacoes: [],
  selectedFotoceuticos: [],
};

const IntegrationContext = createContext<IntegrationContextData>(
  {} as IntegrationContextData,
);

export const IntegrationProvider: React.FC<IntegrationProviderProps> = ({
  children,
}: IntegrationProviderProps) => {
  const [state, setState] = useState<IntegrationState>(initialState);

  const [selectedOptions, setSelectedOptions] = useState<IntegrationItems>(
    initialItems,
  );

  const updateExamesStatus = (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => {
    setState(prev => ({ ...prev, examesStatus: status }));
    setSelectedOptions(prev => ({ ...prev, selectedExames: options }));
  };

  const updateMedicamentosStatus = (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => {
    setState(prev => ({ ...prev, medicamentosStatus: status }));
    setSelectedOptions(prev => ({ ...prev, selectedMedicamentos: options }));
  };

  const updateEncaminhamentosStatus = (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => {
    setState(prev => ({ ...prev, encaminhamentosStatus: status }));
    setSelectedOptions(prev => ({ ...prev, selectedEncaminhamentos: options }));
  };

  const updateOrientacoesStatus = (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => {
    setState(prev => ({ ...prev, orientacoesStatus: status }));
    setSelectedOptions(prev => ({ ...prev, selectedOrientacoes: options }));
  };
  const updateFotoceuticosStatus = (
    status: GeneralStatusType,
    options: OptionIntegracao[],
  ) => {
    setState(prev => ({ ...prev, fotoceuticosStatus: status }));
    setSelectedOptions(prev => ({ ...prev, selectedFotoceuticos: options }));
  };

  const dispatchSolicitarRecursos = useCallback(
    async (passoExecutadoId: number) => {
      const solicitacoes = [
        ...Object.values(selectedOptions)
          .reduce((a, b) => a.concat(b))
          .map((item: OptionIntegracao) => item.value),
      ];

      setState({
        generalStatus: 'loading',
        examesStatus: 'loading',
        medicamentosStatus: 'loading',
        encaminhamentosStatus: 'loading',
        orientacoesStatus: 'loading',
        fotoceuticosStatus: 'loading',
      });

      if (solicitacoes.length) {
        try {
          const {
            results: integrationResponse,
          } = await IntegracaoService.requestSolicitarArtefatos(
            passoExecutadoId,
            {
              solicitacoes,
            },
          );

          if (integrationResponse) {
            setState({
              generalStatus: 'done',
              examesStatus:
                selectedOptions.selectedExames.length > 0
                  ? 'done'
                  : 'none_selected',
              medicamentosStatus:
                selectedOptions.selectedMedicamentos.length > 0
                  ? 'done'
                  : 'none_selected',
              encaminhamentosStatus:
                selectedOptions.selectedEncaminhamentos.length > 0
                  ? 'done'
                  : 'none_selected',
              orientacoesStatus:
                selectedOptions.selectedOrientacoes.length > 0
                  ? 'done'
                  : 'none_selected',
              fotoceuticosStatus:
                selectedOptions.selectedFotoceuticos.length > 0
                  ? 'done'
                  : 'none_selected',
            });
          }

          return integrationResponse;
        } catch (error) {
          setState({
            generalStatus: 'error',
            examesStatus:
              selectedOptions.selectedExames.length > 0
                ? 'error'
                : 'none_selected',
            medicamentosStatus:
              selectedOptions.selectedMedicamentos.length > 0
                ? 'error'
                : 'none_selected',
            encaminhamentosStatus:
              selectedOptions.selectedEncaminhamentos.length > 0
                ? 'error'
                : 'none_selected',
            orientacoesStatus:
              selectedOptions.selectedOrientacoes.length > 0
                ? 'error'
                : 'none_selected',
            fotoceuticosStatus:
              selectedOptions.selectedFotoceuticos.length > 0
                ? 'error'
                : 'none_selected',
          });
        }
      }

      return null;
    },
    [selectedOptions],
  );

  const dispatchCancelarRecursos = useCallback(() => {
    setState(initialState);
    setSelectedOptions(initialItems);
  }, []);

  const toastNotification = useCallback(() => {
    toast.dark(
      () => (
        <Toast check>
          <p>Prescrições realizadas com sucesso!</p>
        </Toast>
      ),
      {
        closeButton: false,
        transition: Slide,
        position: 'bottom-center',
      },
    );
  }, []);

  useEffect(() => {
    const status = [
      state.examesStatus,
      state.medicamentosStatus,
      state.encaminhamentosStatus,
      state.orientacoesStatus,
      state.fotoceuticosStatus,
    ];

    if (status.includes('some_selected') || status.includes('all_selected')) {
      setState(prev => ({ ...prev, generalStatus: 'some_selected' }));
    }

    if (status.every(value => value === 'none_selected')) {
      setState(prev => ({ ...prev, generalStatus: 'none_selected' }));
    }

    if (status.some(value => value === 'error')) {
      setState(prev => ({ ...prev, generalStatus: 'error' }));
    }
  }, [
    state.examesStatus,
    state.medicamentosStatus,
    state.encaminhamentosStatus,
    state.orientacoesStatus,
    state.fotoceuticosStatus,
  ]);

  useEffect(() => {
    if (
      state.generalStatus === 'loading' &&
      [
        state.examesStatus,
        state.medicamentosStatus,
        state.encaminhamentosStatus,
        state.orientacoesStatus,
        state.fotoceuticosStatus,
      ].every(value => value === 'done' || value === 'none_selected')
    ) {
      setState(prev => ({ ...prev, generalStatus: 'done' }));
    }
  }, [state]);

  useEffect(() => {
    if (state.generalStatus === 'done') {
      toastNotification();
    }
  }, [state.generalStatus, toastNotification]);

  useLayoutEffect(() => {
    setState(initialState);
    setSelectedOptions(initialItems);
  }, []);

  return (
    <IntegrationContext.Provider
      value={[
        state,
        selectedOptions,
        {
          updateExamesStatus,
          updateMedicamentosStatus,
          updateEncaminhamentosStatus,
          updateOrientacoesStatus,
          updateFotoceuticosStatus,
          dispatchSolicitarRecursos,
          dispatchCancelarRecursos,
        },
      ]}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

export function useIntegrationContext(): IntegrationContextData {
  return useContext(IntegrationContext);
}
