import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { CollapseButton, CloseDetailsIcon, OpenDetailsIcon } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  collapsed: boolean;
}

const DetailsCollapseButton: React.FC<Props> = ({ collapsed, ...props }) => {
  return (
    <TransitionGroup>
      <CSSTransition
        key={collapsed ? 'close-details' : 'open-details'}
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <CollapseButton collapsed={collapsed} {...props}>
          {collapsed ? <CloseDetailsIcon /> : <OpenDetailsIcon />}
        </CollapseButton>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DetailsCollapseButton;
