import React from 'react';
import * as yup from 'yup';
import fhirpath from 'fhirpath';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ArtifactFieldConfig,
  ArtifactFieldInfo,
  ArtifactFormConfig,
  ArtifactFormData,
  FHIRDataTypeMap,
  ResourceSubmitHandler,
  UseArtifactFormMethods,
} from './ArtifactForm.types';

export function toInternalCodeableConceptValue<
  RType extends fhir4.DomainResource
>(
  resource: RType,
  element: keyof RType,
  value: FHIRDataTypeMap['CodeableConcept'],
  oneToMany = false,
): RType {
  let valueCodeableConcept: fhir4.CodeableConcept;
  if (typeof value === 'string') {
    valueCodeableConcept = {
      text: value,
    };
  } else {
    valueCodeableConcept = { coding: [value] };
  }

  const valueWithCardinality = oneToMany
    ? [valueCodeableConcept]
    : valueCodeableConcept;

  return {
    ...resource,
    [element]: valueWithCardinality,
  };
}

export function artifactFormResolver<RType extends fhir4.DomainResource>(
  config: ArtifactFormConfig<RType>,
): Resolver {
  const objectSchema = yup
    .object(
      config.reduce((spec, field) => {
        let fieldSchema =
          field.type === 'integer' || field.type === 'decimal'
            ? yup
                .number()
                .transform(val => (Number.isNaN(val) ? null : val))
                .nullable()
            : yup.string();

        if (!field.readonly && field.required) {
          fieldSchema = fieldSchema.required('Este campo é obrigatório');
        }

        return {
          ...spec,
          [field.name]: fieldSchema,
        };
      }, {}),
    )
    .required();

  return yupResolver(objectSchema);
}

export function artifactDefaultValues<RType extends fhir4.DomainResource>(
  resource: RType,
  config: ArtifactFormConfig<RType>,
): ArtifactFormData {
  return config.reduce<ArtifactFormData>((data, { name, path }) => {
    const result = fhirpath.evaluate(resource, path);
    const defaultValue = result.length > 0 && result[0].toString();

    return {
      ...data,
      [name]: defaultValue || '',
    };
  }, {});
}

export function useArtifactForm<RType extends fhir4.DomainResource>(
  resource: RType,
  config: ArtifactFormConfig<RType>,
): UseArtifactFormMethods<RType> {
  const handleResourceSubmit = React.useCallback(
    (onValid: ResourceSubmitHandler<RType>) => {
      return (data: ArtifactFormData) => {
        const result = config.reduce<RType>((artifact, field) => {
          const value = data[field.name];

          /**
           * Remove do objeto o elemento quando o seu valor for vazio
           *
           * TODO: Dependendo do contexto de uso no futuro, não faz sentido
           * remover o elemento do objeto aqui. Talvez seja melhor as próprias
           * configurações do campo definirem o que fazer com valores de entrada
           * vazios
           */
          const isEmptyValue =
            value === null || value === '' || value === undefined;
          if (isEmptyValue) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [field.element]: _, ...obj } = artifact;
            return obj as RType;
          }

          return field.toInternalValue(artifact, value as never);
        }, resource);

        onValid(result);
      };
    },
    [config, resource],
  );

  const { handleSubmit, reset: resetForm, ...useFormMethods } = useForm({
    resolver: artifactFormResolver(config),
    defaultValues: artifactDefaultValues(resource, config),
  });

  const reset = React.useCallback(() => {
    const defaults = artifactDefaultValues(resource, config);
    resetForm(defaults);
  }, [config, resetForm, resource]);

  return {
    ...useFormMethods,
    reset,
    handleSubmit: (handler: ResourceSubmitHandler<RType>) =>
      handleSubmit(handleResourceSubmit(handler)),
  };
}

function isValidConfigValue(value: unknown): boolean {
  // Check if the value is not null, not undefined, and not NaN

  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === 'string') {
    return value.trim() !== '';
  }

  if (typeof value === 'number') {
    return !Number.isNaN(value);
  }

  return true;
}

function buildFieldConfig<RType extends fhir4.DomainResource>(
  config: ArtifactFieldConfig<RType>,
  info: ArtifactFieldInfo,
): ArtifactFieldConfig<RType> {
  const mergedConfig: ArtifactFieldConfig<RType> = { ...config };

  Object.entries(info).forEach(([key, infoValue]) => {
    if (isValidConfigValue(infoValue)) {
      mergedConfig[
        key as keyof ArtifactFieldConfig<RType>
      ] = infoValue as never;
    }
  });

  return mergedConfig;
}

export function buildFormConfig<RType extends fhir4.DomainResource>(
  info: ArtifactFieldInfo[],
  config: ArtifactFormConfig<RType>,
): ArtifactFormConfig<RType> {
  return info.reduce<ArtifactFormConfig<RType>>((form, fieldInfo) => {
    const fieldConfig = config.find(field => field.name === fieldInfo.name);
    if (fieldConfig) {
      return [...form, buildFieldConfig(fieldConfig, fieldInfo)];
    }

    return form;
  }, []);
}
