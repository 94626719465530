import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';

export const Box = styled.div`
  padding: 8px 0;

  background-color: ${colors.white};

  box-shadow: ${shadows.mediumShadow};
  border-radius: 8px;
`;

export const Options = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
