/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { Container } from './styles';

interface TabsProps extends React.PropsWithChildren {
  value?: React.Key;
  style?: React.CSSProperties;
  className?: string;
}

interface ITabsContext {
  value?: React.Key;
  setValue: (value: React.Key) => void;
}

export const TabsContext = React.createContext<ITabsContext>({
  setValue: () => {},
});

const Tabs: React.FC<TabsProps> = ({
  children,
  value: selectedValue,
  style,
  className,
}) => {
  const [value, setValue] = React.useState<React.Key | undefined>();

  React.useEffect(() => {
    if (selectedValue !== undefined) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <TabsContext.Provider value={{ value, setValue }}>
      <Container style={style} className={className}>
        {children}
      </Container>
    </TabsContext.Provider>
  );
};

export default Tabs;
