import React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Tag, TagEmpresa } from '@/models/Tags';

import Button from '@/components/Button';

import StepFooter from '../StepFooter';
import { Container, TagForm, TagField } from './styles';

interface Props {
  questionnaire: TagEmpresa[];
  response?: Tag[];
  disabled?: boolean;
  onSubmitSuccess?: () => void;
  onLeave?: () => void;
}

interface TagFormData {
  [key: string]: string;
}

const ProtocoloExecutadoTagForm: React.FC<Props> = ({
  questionnaire: tags,
  disabled = false,
  response = [],
  onSubmitSuccess,
  onLeave,
}) => {
  const { handleSubmit, register, control } = useForm<TagFormData>({
    defaultValues: response.reduce<TagFormData>(
      (formData, tag) => ({ ...formData, [tag.nome]: tag.valor }),
      {},
    ),
  });

  const watchForm = useWatch({
    control,
    name: tags.map(tag => tag.nome) || [],
  });

  const isValid = React.useMemo(() => {
    return Object.values(watchForm).some(value => !value || value === '');
  }, [watchForm]);

  return (
    <Container>
      <h2>Por favor, preencha o formulário abaixo para continuar.</h2>
      <TagForm onSubmit={onSubmitSuccess && handleSubmit(onSubmitSuccess)}>
        {tags.map(tag => (
          <TagField
            ref={register}
            key={tag.nome}
            name={tag.nome}
            title={tag.descricao}
            disabled={disabled}
            required
          />
        ))}
      </TagForm>

      {!disabled && (
        <StepFooter>
          <Button theme="secondary" onClick={onLeave}>
            Sair do protocolo
          </Button>
          <Button type="submit" theme="primary" disabled={isValid}>
            Iniciar atendimento
          </Button>
        </StepFooter>
      )}
    </Container>
  );
};

export default ProtocoloExecutadoTagForm;
