import React from 'react';

import { PassoExecutado } from '@/models/PassoExecutado';

import IconMedicamento from '@/assets/medicamentos-icon.svg';

import { FhirArtefatoType } from '@/features/enterprise/models/types';
import ListaMedicamentosDefault from './index.default';
import ListaMedicamentosFhir from './index.fhir';

import { ItemList, SectionTitle } from './styles';

interface ListaMedicamentosProps {
  medicamentos: FhirArtefatoType[];
  passoExecutado: PassoExecutado;
  integracaoEnterpriseAtiva?: boolean;
}

const ListaMedicamentos: React.FC<ListaMedicamentosProps> = ({
  medicamentos,
  passoExecutado,
  integracaoEnterpriseAtiva,
}) => {
  return (
    <ItemList>
      <SectionTitle>
        <img src={IconMedicamento} alt="Medicamento Icone" />

        <h2>Medicamentos sugeridos</h2>
      </SectionTitle>

      {integracaoEnterpriseAtiva ? (
        <ListaMedicamentosFhir
          medicamentos={medicamentos}
          passo={passoExecutado}
        />
      ) : (
        <ListaMedicamentosDefault medicamentos={medicamentos} />
      )}
    </ItemList>
  );
};

export default ListaMedicamentos;
