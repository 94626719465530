import fhirpath from 'fhirpath';

export function codeableConceptDisplay<RType extends fhir4.Resource>(
  resource: RType,
  element: keyof RType,
): string {
  const displays = fhirpath.evaluate(
    resource,
    `${element.toString()}.select(text | coding.display).first()`,
  );

  if (displays.length) {
    return displays[0];
  }

  return '';
}
