import styled, { css } from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';
import { HeadingSmall, Text } from '@/design/typography';

interface StyledProps {
  hasError: boolean;
}

export const Title = styled.label`
  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};
  margin-bottom: 8px;
`;

export const HelperText = styled.span`
  ${Text(14)};
  margin-top: 4px;
`;

const InputMixin = css`
  ${Text(16)};
  width: 100%;

  // Indie
  color: ${colors.blackAlpha200};
  padding: 8px;
  border: 1px solid ${colors.gray100};
  border-radius: 8px;

  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-in;

  &::placeholder {
    color: ${colors.gray200};
  }

  // Focus
  &:focus:not(:disabled):not(:read-only) {
    border-color: ${colors.purple100};
    box-shadow: ${shadows.mediumShadow};
  }

  // Active
  &:focus:not(:disabled):not(:read-only) {
    border-color: ${colors.purple100};
  }

  // Disabled
  &:disabled {
    color: ${colors.gray200};
    background-color: ${colors.gray50};
    cursor: not-allowed;
  }

  &:read-only {
    background-color: ${colors.gray50};
  }
`;

export const Input = styled.input`
  ${InputMixin};
`;

export const AddonLabel = styled.span`
  position: absolute;
  right: 8px;
  top: 8px;

  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};
`;

export const Textarea = styled.textarea`
  ${InputMixin};
`;

export const Field = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // Error
  ${props =>
    props.hasError &&
    css`
      > ${HelperText} {
        color: ${colors.red100};
      }

      input,
      textarea {
        border-color: ${colors.red100};

        // Focus
        &:focus:not(:disabled):not(:read-only) {
          border-color: ${colors.red100};
        }

        // Active
        &:focus:not(:disabled):not(:read-only) {
          border-color: ${colors.red100};
        }
      }
    `}
`;

export const InputContent = styled.div`
  position: relative;
  display: flex;

  flex: 1;
  width: 100%;
`;
