import { createGlobalStyle } from 'styled-components';
import colors from './colors';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: none;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${colors.backgroundColor};
  }

  body, input, button, textarea {
    font: 16px 'Source Sans Pro', sans-serif;
    color: ${colors.black};
  }

  button {
    cursor: pointer;
    background: none;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  /* React-Toastify */
  /* https://github.com/fkhadra/react-toastify#readme */

  .Toastify__toast {
    padding: 16px 24px;
    border-radius: 8px;
    max-width: 440px;
    font: 16px 'Source Sans Pro', sans-serif;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast-container--bottom-center {
    left: 24px;
    bottom: 40px;
    transform: translateX(0);
  }

  .Toastify__toast-container {
    width: auto;
  }

  .tippy-tooltip {
    font-size: 12px;
    background-color: #2f3139;
    border-radius: 4px;
  }

`;
