import axios from 'axios';
import ReactGA from 'react-ga4';

import api from '@/sevices/api';
import { withTimingTrack } from '@/utils/analytics';

import {
  JustificativaStatus,
  ProtocoloExecutado,
  ProtocoloExecutadoResumo,
} from '@/models/ProtocoloExecutado';

import {
  AbrirProtocoloPayload,
  CriarExecucaoPayload,
  ExecutarPassoPayload,
  GetFlowchartPayload,
  GetFlowchartResponse,
  RedirecionarFluxoAPIPayload,
  RedirecionarFluxoPayload,
  SetIdentificadoresProtocoloPayload,
  SetIdentificadoresProtocoloResponse,
} from '../types';

function sendOpeningTiming(protocolo: ProtocoloExecutado, timingValue: number) {
  ReactGA.send({
    hitType: 'timing',
    timingCategory: 'Execução de protocolos',
    timingVar: protocolo.codigo,
    timingValue,
    timingLabel: 'Abertura de protocolo',
  });
}

interface GetExecucaoParams {
  expand?: string[];
  omit?: string[];
}

async function getExecucao(
  execucaoId: string,
  params: GetExecucaoParams = {},
): Promise<ProtocoloExecutado> {
  const execucao = (
    await api.get<ProtocoloExecutado>(`/protocolos-executados/${execucaoId}`, {
      params,
    })
  ).data;
  return execucao;
}

async function getExecucaoResumo(
  execucaoId: string,
): Promise<ProtocoloExecutadoResumo> {
  const execucao = (
    await api.get<ProtocoloExecutadoResumo>(
      `/protocolos-executados/${execucaoId}/resumo`,
    )
  ).data;
  return execucao;
}

async function criarExecucao({
  protocoloId,
}: CriarExecucaoPayload): Promise<ProtocoloExecutado> {
  return (
    await api.post<ProtocoloExecutado>(
      `/protocolos/${protocoloId}/criar-execucao/`,
      {},
    )
  ).data;
}

async function abrirProtocolo({
  codigoProtocolo,
}: AbrirProtocoloPayload): Promise<ProtocoloExecutado> {
  return (
    await api.post<ProtocoloExecutado>(`/protocolos-executados/`, {
      codigo_protocolo: codigoProtocolo,
    })
  ).data;
}

async function executarPasso({
  passoExecutado,
  respostas,
}: ExecutarPassoPayload): Promise<ProtocoloExecutado> {
  const protocolo = (
    await api.put<ProtocoloExecutado>(
      `/passos-executados/${passoExecutado.id}`,
      respostas,
    )
  ).data;
  return protocolo;
}

async function redirecionarFluxo({
  passoExecutado,
  opcoes,
}: RedirecionarFluxoPayload): Promise<[ProtocoloExecutado, boolean]> {
  const multiple = opcoes.length > 1;
  const codes = opcoes.map(({ codigo }) => codigo);
  const serviceURL = multiple
    ? passoExecutado.planejar_redirecionamentos_protocolo_url
    : passoExecutado.redirecionar_protocolo_url;

  const payloadAPI: RedirecionarFluxoAPIPayload = {
    codigo_protocolo_destino: multiple ? codes : codes[0],
  };

  return [
    (await api.post<ProtocoloExecutado>(serviceURL, payloadAPI)).data,
    !multiple,
  ];
}

async function getFlowchartProtocolo({
  protocoloExecutadoId,
}: GetFlowchartPayload): Promise<string> {
  try {
    const { content } = (
      await api.get<GetFlowchartResponse>(
        `/protocolos-executados/${protocoloExecutadoId}/fluxograma/`,
      )
    ).data;

    return content;
  } catch (err) {
    return '';
  }
}

interface ProtocoloExecutadoTags {
  tags: {
    nome: string;
    valor: string;
  }[];
}

async function setProcoloExecutadoTags({
  protocoloExecutadoId,
  tags,
}: SetIdentificadoresProtocoloPayload): Promise<ProtocoloExecutadoTags> {
  const { data } = (
    await api.patch<SetIdentificadoresProtocoloResponse>(
      `/protocolos-executados/${protocoloExecutadoId}/tags/`,
      { tags },
    )
  ).data;

  return data;
}

async function cancelarExecucao(
  protocolo: ProtocoloExecutado,
): Promise<boolean> {
  if (protocolo.status === 'CANCELADO') {
    return false;
  }

  try {
    await api.post<ProtocoloExecutado>(
      `/protocolos-executados/${protocolo.id}/cancelar/`,
    );
    return true;
  } catch {
    return false;
  }
}

function cancelarExecucaoSilently(protocolo: ProtocoloExecutado): boolean {
  if (navigator.sendBeacon && protocolo.status !== 'CANCELADO') {
    const baseURL = process.env.REACT_APP_API_URL;
    return navigator.sendBeacon(
      `${baseURL}/protocolos-executados/${protocolo.id}/cancelar/`,
    );
  }

  return false;
}

async function justificarStatusProtocolo(
  execucaoId: string,
  justificativa: { texto?: string; modelo?: number },
): Promise<JustificativaStatus | null> {
  try {
    const { modelo, texto } = justificativa;

    const { justificativa_status } = (
      await api.post<{ justificativa_status: JustificativaStatus }>(
        `/protocolos-executados/${execucaoId}/justificativas/`,
        {
          modelo,
          texto,
        },
      )
    ).data;

    return justificativa_status;
  } catch (err) {
    // Ignora erros de requisição ou servidor p/ não bloquear usuário e evitar
    // cláusulas try/cacth dentro de componentes sem necessidade
    if (axios.isAxiosError(err)) {
      return null;
    }

    throw err;
  }
}

async function pausarExecucao(
  protocolo: ProtocoloExecutado,
): Promise<ProtocoloExecutado> {
  return (
    await api.post<ProtocoloExecutado>(
      `/protocolos-executados/${protocolo.id}/pausar/`,
      {},
    )
  ).data;
}

export default {
  criarExecucao,
  getExecucao,
  getExecucaoResumo,
  abrirProtocolo: withTimingTrack(abrirProtocolo, sendOpeningTiming),
  executarPasso,
  redirecionarFluxo,
  getFlowchartProtocolo,
  setProcoloExecutadoTags,
  cancelarExecucao,
  cancelarExecucaoSilently,
  justificarStatusProtocolo,
  pausarExecucao,
};
