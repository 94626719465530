import { PassoExecutado } from '@/models/PassoExecutado';
import { RespostaPayload, ValorResposta } from '../types';

export function getPassoRespostaPayload(
  passo: PassoExecutado,
): RespostaPayload {
  return passo.perguntas.reduce<RespostaPayload>((payload, pergunta) => {
    // eslint-disable-next-line no-debugger
    let value: ValorResposta = pergunta.variavel.valor;

    if (pergunta.variavel.opcoes.length > 0) {
      const selecionados = pergunta.variavel.opcoes
        .filter(opt => opt.selecionado)
        .map(opt => opt.id);

      if (pergunta.variavel.multipla_escolha) {
        value = selecionados;
      } else {
        value = selecionados.length ? selecionados[0] : null;
      }
    }
    return { ...payload, [pergunta.variavel.nome]: value };
  }, {});
}

export function listInvalidRespostaPayload(
  passo: PassoExecutado,
  payload: RespostaPayload,
  excludedItems: string[] = [],
): string[] {
  const withExcludentOptionNames = passo.perguntas
    .filter(pergunta =>
      pergunta.variavel.opcoes.some(opcao => opcao.excludente),
    )
    .map(pergunta => pergunta.variavel.nome);

  return Object.entries(payload).reduce((invalidKeys, [varname, value]) => {
    // Perguntas de múltipla escolha com opção excludente são automaticamente
    //  obrigatórias
    const hasExcludentOptionAndEmptyValue =
      withExcludentOptionNames.includes(varname) &&
      Array.isArray(value) &&
      !value.length;

    const removeFromValidation = excludedItems.includes(varname);

    const isNullOrBlankValue = value === null || value === '';

    if (
      (hasExcludentOptionAndEmptyValue || isNullOrBlankValue) &&
      !removeFromValidation
    ) {
      invalidKeys.push(varname);
    }

    return invalidKeys;
  }, [] as string[]);
}

export function isValidRespostaPayload(
  passo: PassoExecutado,
  payload: RespostaPayload,
  excludedItems: string[] = [],
): boolean {
  return listInvalidRespostaPayload(passo, payload, excludedItems).length === 0;
}
