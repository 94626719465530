import styled, { css } from 'styled-components';
import colors from '@/design/colors';
import { HeadingLarge } from '@/design/typography';

import CheckboxAlternatives from '@/components/CheckboxAlternatives';
import RadioAlternatives from '@/components/RadioAlternatives';
import { AlternativeInput } from '@/components/SelectionControlAlternatives/styles';

import {
  InputContent as ProtocoloInputContent,
  InputText as ProtocoloInputText,
} from '@/features/dashboard/components/InputProtocolo/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  width: 100%;
  padding: 0px 24px;

  row-gap: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    ${HeadingLarge(16)};
    margin-left: 12px;
  }
`;

const Suggestions = css`
  row-gap: 16px;

  ${AlternativeInput} {
    &:hover:not(:disabled) {
      ${ProtocoloInputText} {
        color: ${colors.purple100};
      }
    }

    &:disabled {
      ${ProtocoloInputContent} {
        opacity: 0.5;
      }
    }
  }
`;

export const CheckboxSuggestions = styled(CheckboxAlternatives)`
  ${Suggestions}
` as typeof CheckboxAlternatives;

export const RadioSuggestions = styled(RadioAlternatives)`
  ${Suggestions}
` as typeof RadioAlternatives;

export const InputSelection = styled.div<{
  disabled: boolean;
  selected: boolean;
}>`
  ${props =>
    props.disabled &&
    css`
      button {
        background: ${props.selected ? colors.gray50 : colors.white};
        border: 1px solid ${props.selected ? colors.gray100 : colors.gray50};

        span {
          color: ${props.selected ? colors.gray200 : colors.gray100};
        }

        img {
          opacity: 0.5;
        }

        &:hover {
          span {
            color: ${props.selected
              ? colors.gray200
              : colors.gray100} !important;
          }
          box-shadow: none !important;
        }
      }
    `}
`;
