/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import debounce from 'lodash.debounce';
import { FiX } from 'react-icons/fi';

import { Container, Input, SearchIcon, ClearButton } from './styles';

interface SearchProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
  > {
  debounceTimeInMS: number;
  onChange?: (value: string) => void;
}

const Search: React.FC<SearchProps> = ({
  debounceTimeInMS,
  onChange,
  style,
  className,
  ...props
}) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [clearable, setClearble] = React.useState(false);

  const debounceChange = React.useMemo(() => {
    if (onChange) {
      return debounce(onChange, debounceTimeInMS);
    }

    return undefined;
  }, [debounceTimeInMS, onChange]);

  const handleChange = React.useCallback(
    (value: string) => {
      if (debounceChange) {
        debounceChange(value);
      }

      setClearble(!!value);
    },
    [debounceChange],
  );

  const handleClear = React.useCallback(() => {
    if (ref.current) {
      ref.current.value = '';

      // Clear should trigger event immediately
      if (onChange) {
        onChange('');
      }

      setClearble(false);
    }
  }, [onChange]);

  React.useEffect(() => {
    return () => {
      if (debounceChange) {
        debounceChange.cancel();
      }
    };
  }, [debounceChange]);

  return (
    <Container style={style} className={className}>
      <Input
        ref={ref}
        {...props}
        onChange={event => handleChange(event.target.value)}
      />
      <SearchIcon />

      {clearable && (
        <ClearButton onClick={handleClear}>
          <FiX size={18} />
        </ClearButton>
      )}
    </Container>
  );
};

export default Search;
