import React from 'react';
import ReactModal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'smoothscroll-anchor-polyfill';
import SmoothScroll from 'smoothscroll-polyfill';

import AuthProvider from '@/features/auth/providers/AuthProvider';
import { ExecucaoProvider } from '@/features/execucao/providers/ExecucaoProvider';
import FeaturesProvider from '@/features/features/providers/FeaturesProvider';

import ModalFinalizarProtocolo from '@/features/execucao/components/ModalFinalizarProtocolo';
import ModalSairProtocolo from '@/features/execucao/components/ModalSairProtocolo';

import ModalFontesProtocolo from '@/features/execucao/components/ModalFontesProtocolo';

import EnterpriseProvider from '@/features/enterprise/contexts/EnterpriseContext';
import FlowchartProvider from '@/features/execucao/providers/FlowchartProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { EventsProvider } from './features/tracking/context/EventsContext';
import Routes from './routes';
import GlobalStyle from './styles/global';

// Kick off the polyfill
SmoothScroll.polyfill();

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');

// Create a client
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* Provider de rotas */}
      <Router>
        {/* Providers de features */}
        <AuthProvider>
          <FeaturesProvider>
            {/* <FeedbackProvider> */}
            <ExecucaoProvider>
              <EventsProvider>
                <FlowchartProvider>
                  <EnterpriseProvider>
                    {/* Modais fixas */}
                    {/* <FeedbackModal /> */}
                    <ModalFinalizarProtocolo />
                    <ModalSairProtocolo />
                    <ModalFontesProtocolo />
                    {/* Rotas */}
                    <Routes />
                  </EnterpriseProvider>
                </FlowchartProvider>
              </EventsProvider>
            </ExecucaoProvider>
            {/* </FeedbackProvider> */}
          </FeaturesProvider>
        </AuthProvider>
      </Router>

      <GlobalStyle />

      <ToastContainer
        position="bottom-left"
        autoClose={4000}
        limit={1}
        hideProgressBar
        closeButton={false}
      />
    </QueryClientProvider>
  );
};

export default App;
