import React from 'react';

import {
  CheckboxContainer,
  CheckboxMarker,
  RadioContainer,
  RadioMarker,
} from './styles';
import { CheckboxProps, RadioProps } from './types';

export const Checkbox: React.FC<Partial<CheckboxProps>> = ({
  disabled = false,
  state = 'unmarked',
  size = 'normal',
  style,
  className,
}) => {
  return (
    <CheckboxContainer
      style={style}
      className={className}
      disabled={disabled}
      size={size}
      state={state}
    >
      {state !== 'unmarked' && (
        <CheckboxMarker disabled={disabled} size={size} state={state} />
      )}
    </CheckboxContainer>
  );
};

export const Radio: React.FC<Partial<RadioProps>> = ({
  disabled = false,
  state = 'unmarked',
  size = 'normal',
  style,
  className,
}) => {
  return (
    <RadioContainer
      style={style}
      className={className}
      disabled={disabled}
      size={size}
      state={state}
    >
      {state !== 'unmarked' && (
        <RadioMarker disabled={disabled} size={size} state={state} />
      )}
    </RadioContainer>
  );
};

export default {
  Radio,
  Checkbox,
};
