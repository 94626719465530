import React from 'react';
import { BsInfo } from 'react-icons/bs';

import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';

import Button from '@/components/Button';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import { ZendeskSettings, ZendeskWidget } from '@/zendesk';
import { FiMessageCircle } from 'react-icons/fi';
import AutoriaProtocolo from '../AutoriaProtocolo';
import {
  ButtonInfo,
  Container,
  ExecucaoInfo,
  HeaderColumn,
  HeaderRow,
  Info,
  SupportInfo,
} from './styles';

declare global {
  interface Window {
    zESettings?: ZendeskSettings;
    zE?: ZendeskWidget;
  }
}

type Props = { protocolo: ProtocoloExecutado };
type DetailsProps = {
  autoria: ProtocoloExecutado['autoria'];
  timeoutInMS?: number;
};

/**
 * TODO: Generalizar este componente
 * Comportamento semelhante ao de SecondaryMenu, com a execção que o dropdown é
 * exibido via hover ao invés de onclick
 */
const Details: React.FC<DetailsProps> = ({ autoria, timeoutInMS = 300 }) => {
  const [menuShow, setMenuShow] = React.useState(false);
  const [displayTimeout, setDisplayTimeout] = React.useState<NodeJS.Timeout>();

  React.useEffect(() => {
    return () => clearTimeout(displayTimeout);
  }, [displayTimeout]);

  return (
    autoria && (
      <Info
        onMouseEnter={() => {
          clearTimeout(displayTimeout);
          setMenuShow(true);
        }}
        onMouseLeave={() => {
          clearTimeout(displayTimeout);
          setDisplayTimeout(setTimeout(() => setMenuShow(false), timeoutInMS));
        }}
      >
        <ButtonInfo>
          <BsInfo size={22} />
        </ButtonInfo>
        {menuShow && <AutoriaProtocolo autoria={autoria} />}
      </Info>
    )
  );
};

const HeaderTitleProtocoloExecutado: React.FC<Props> = ({
  protocolo: { nome, autoria, id },
}) => {
  const { user } = useAuth();
  const [{ mode }] = useEnterpriseContext();

  const contatoURL = React.useMemo(() => {
    if (user && user.empresa) {
      return user.empresa.url_contato;
    }

    return null;
  }, [user]);

  const onButtonSupportClick = React.useCallback(() => {
    if (contatoURL && window.zE) {
      window.zE('messenger', 'open');
    }
  }, [contatoURL]);

  React.useLayoutEffect(() => {
    const script = document.createElement('script');
    if (contatoURL && contatoURL.includes('static.zdassets.com')) {
      script.src = contatoURL;

      script.id = 'ze-snippet';

      document.body.appendChild(script);
    }

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [contatoURL]);

  React.useLayoutEffect(() => {
    if (typeof window.zE === 'function') {
      window.zE('messenger:on', 'close', () => {
        if (window.zE) window.zE('messenger', 'hide');
      });
    }
  }, []);

  return (
    <Container>
      <HeaderColumn>
        <HeaderRow>
          <h1>{nome}</h1>

          {autoria && <Details autoria={autoria} />}
        </HeaderRow>
        <ExecucaoInfo>Número de execução: {id}</ExecucaoInfo>
      </HeaderColumn>

      {mode === 'integrated' && !!contatoURL && (
        <SupportInfo>
          <Button theme="secondary" onClick={onButtonSupportClick}>
            <FiMessageCircle size={16} />
            Fale com a gente
          </Button>
        </SupportInfo>
      )}
    </Container>
  );
};

export default HeaderTitleProtocoloExecutado;
