import colors from '@/design/colors';
import { Text } from '@/design/typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  align-items: center;
`;

export const SupportInfo = styled.div`
  margin-left: auto;
`;

export const Info = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`;

export const ExecucaoInfo = styled.div`
  color: ${colors.blackAlpha100};

  ${Text(12)}
  font-weight: 600;
`;

export const ButtonInfo = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border-radius: 50%;

  background-color: ${colors.gray50};

  &:hover:not(:disabled) {
    background-color: ${colors.gray100};
  }

  transition: background-color 300ms ease-in;
`;
