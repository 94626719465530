import React from 'react';

import { ArtifactType } from '@/features/artifacts/models/ArtifactForm';
import {
  ArtifactFieldInfo,
  ArtifactFormConfig,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import SolicitacaoExameFormConfig from '../forms/SolicitacaoExameFormConfig';
import EncaminhamentoFormConfig from '../forms/EncaminhamentoFormConfig';
import { buildFormConfig } from '../components/ArtifactForm/ArtifactForm.helpers';

type ArtifactFormFHIRTypes =
  | ArtifactFormConfig<fhir4.ServiceRequest>
  | ArtifactFormConfig<fhir4.MedicationRequest>
  | ArtifactFormConfig<fhir4.Condition>
  | ArtifactFormConfig<fhir4.CarePlan>;

interface ArtifactFormTypeSettings {
  type: ArtifactType;
  form: ArtifactFormFHIRTypes;
}

const ArtifactForms: ArtifactFormTypeSettings[] = [
  {
    type: 'solicitacao-exame',
    form: SolicitacaoExameFormConfig,
  },
  {
    type: 'encaminhamento',
    form: EncaminhamentoFormConfig,
  },
];

export function useArtifactFormConfig<RType extends fhir4.DomainResource>(
  artifactType: ArtifactType,
  fallback: ArtifactFieldInfo[],
): ArtifactFormConfig<RType> {
  const { user } = useAuth();

  const original = React.useMemo(() => {
    const formSettings = ArtifactForms.find(
      ({ type }) => type === artifactType,
    );

    if (formSettings) {
      return (formSettings.form as unknown) as ArtifactFormConfig<RType>;
    }

    throw new Error(
      `Não existe uma configuração de formulário disponível para o tipo "${artifactType}".`,
    );
  }, [artifactType]);

  const config = React.useMemo(() => {
    let info = fallback;

    if (user && user.empresa) {
      const form = user.empresa.forms.find(
        ({ artifact_type }) => artifact_type === artifactType,
      );

      if (form) {
        info = form.fields.map<ArtifactFieldInfo>(
          ({ fieldname: name, label, readonly, required }) => ({
            name,
            label,
            readonly,
            required,
          }),
        );
      }
    }

    return buildFormConfig(info, original);
  }, [artifactType, fallback, original, user]);

  return config;
}
