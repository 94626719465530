/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiShuffle } from 'react-icons/fi';

import { OpcaoRedirecionamento } from '@/models/OpcaoRedirecionamento';

import {
  InputContent as ProtocoloInputContent,
  InputIcon as ProtocoloInputIcon,
  InputText as ProtocoloInputText,
} from '@/features/dashboard/components/InputProtocolo/styles';
import Information from '@/components/Information';

import {
  Container,
  Header,
  CheckboxSuggestions,
  RadioSuggestions,
} from './styles';

interface BoxSugestoesProtocolosProps {
  options: OpcaoRedirecionamento[];
  value: OpcaoRedirecionamento[];
  onChange: (value: OpcaoRedirecionamento[]) => void;
  multiple?: boolean;
  disabled?: boolean;
}

const BoxSugestoesProtocolos: React.FC<BoxSugestoesProtocolosProps> = ({
  value,
  onChange,
  options,
  disabled = false,
  multiple = false,
}: BoxSugestoesProtocolosProps) => {
  const Alternatives = React.useMemo(() => {
    const selectionProps = {
      disabled,
      options: options.map(({ codigo, nome, imagem_url, selected }) => ({
        label: (
          <ProtocoloInputContent>
            <ProtocoloInputIcon src={imagem_url || undefined} alt="" />
            <ProtocoloInputText>{nome}</ProtocoloInputText>
          </ProtocoloInputContent>
        ),
        value: codigo,
        marked: selected,
      })),
    };
    const codes = value.map(({ codigo }) => codigo);
    if (multiple) {
      return (
        <CheckboxSuggestions
          value={codes}
          onChange={(values: string[]) => {
            onChange(options.filter(({ codigo }) => values.includes(codigo)));
          }}
          {...selectionProps}
        />
      );
    }

    return (
      <RadioSuggestions
        value={codes[0]}
        onChange={(val: string) => {
          onChange(options.filter(({ codigo }) => codigo === val));
        }}
        {...selectionProps}
      />
    );
  }, [disabled, multiple, onChange, options, value]);

  const Info = React.useMemo(() => {
    if (disabled) {
      return undefined;
    }

    if (value.length === 1) {
      return (
        <Information type="info">
          Ao prosseguir você será redirecionado para o protocolo selecionado.
        </Information>
      );
    }

    if (value.length > 1) {
      return (
        <Information type="warning">
          Os protocolos selecionados estarão disponíveis para serem executados
          nas sugestões de protocolos do seu prontuário.
        </Information>
      );
    }

    return undefined;
  }, [disabled, value.length]);

  return (
    <Container>
      <Header>
        <FiShuffle size={20} />
        <h1>Protocolos Sugeridos</h1>
      </Header>

      {Alternatives}

      {Info}
    </Container>
  );
};

export default BoxSugestoesProtocolos;
