import React, { HTMLAttributes } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { Container } from './styles';

interface MarkdownProps extends HTMLAttributes<HTMLElement> {
  text: string;
}

const Markdown = React.forwardRef<HTMLDivElement, MarkdownProps>(
  ({ text, className, style }, ref) => {
    return (
      <Container ref={ref} className={className} style={style}>
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {text}
        </ReactMarkdown>
      </Container>
    );
  },
);

export default Markdown;
