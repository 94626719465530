import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '@/sevices/api';
import { useApiErrors } from '@/utils/forms';
import Button from '@/components/Button';
import { APIValidationError } from '@/types/Api';

import { Container, Title, Form, EmailInput } from './styles';

const schema = Yup.object().shape({
  email: Yup.string().email('Informe um e-mail válido.'),
});

interface FormData {
  email: string;
}

interface ResetPasswordFormProps {
  onSuccess?: (data: FormData) => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSuccess = undefined,
}) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { setApiErrors } = useApiErrors<FormData>({}, { setError });

  const onSubmit = async ({ email }: FormData) => {
    try {
      setLoading(true);
      await api.post('/auth/password/reset/link/', { email });

      if (onSuccess) {
        onSuccess({ email });
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          setApiErrors(err.response.data as APIValidationError<FormData>);
        }

        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Title>
        Informe o e-mail cadastrado. Enviaremos um link para redefinição da
        senha.
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <EmailInput
          required
          ref={register}
          errors={
            errors.email && errors.email.message ? [errors.email.message] : []
          }
        />
        <Button type="submit" theme="primary" loading={loading}>
          Enviar e-mail de redefinição
        </Button>
      </Form>
    </Container>
  );
};

export default ResetPasswordForm;
