import { APIValidationError } from '@/types/Api';
import { useState } from 'react';
import { ErrorOption, FieldName, FieldValues } from 'react-hook-form';

type UseApiErrorsValue<T> = APIValidationError<T>;

interface UseApiErrorsOptions<T> {
  setError?: (name: FieldName<T>, error: ErrorOption) => void;
}

interface UseApiErrorsMethods<T> {
  apiErrors: APIValidationError<T>;
  setApiErrors: (errors: APIValidationError<T>) => void;
  clearApiErrors: () => void;
}

export function useApiErrors<T extends FieldValues>(
  value: UseApiErrorsValue<T>,
  { setError }: UseApiErrorsOptions<T> = {},
): UseApiErrorsMethods<T> {
  const [apiErrors, dispatchSetApiErrors] = useState(value);

  const setApiErrors = (errors: APIValidationError<T>) => {
    dispatchSetApiErrors(errors);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { non_field_errors, ...rest } = errors;
    Object.entries(rest).forEach(([name, values]) => {
      if (setError && values && values.length) {
        setError(name as FieldName<T>, { message: values[0] });
      }
    });
  };

  const clearApiErrors = () => {
    dispatchSetApiErrors({});
  };

  return {
    apiErrors,
    setApiErrors,
    clearApiErrors,
  };
}
