import React from 'react';

import ArtifactForm, {
  ArtifactFormComponentProps,
} from '@/features/artifacts/components/ArtifactForm';
import { ArtifactFieldInfo } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import { useArtifactFormConfig } from '@/features/artifacts/hooks/useArtifactFormConfig';

const EncaminhamentoFormDefault: ArtifactFieldInfo[] = [
  { name: 'reasonCode', label: 'Indicação clínica' },
];

export type FormEncaminhamentoProps = ArtifactFormComponentProps<fhir4.ServiceRequest>;

const FormEncaminhamento: React.FC<FormEncaminhamentoProps> = ({
  resource,
  onSubmit,
  onCancel,
  disabled = false,
  loading = false,
}) => {
  const form = useArtifactFormConfig<fhir4.ServiceRequest>(
    'encaminhamento',
    EncaminhamentoFormDefault,
  );

  return (
    <ArtifactForm
      fields={form}
      resource={resource}
      loading={loading}
      disabled={disabled}
      onResourceSubmit={onSubmit}
      onResourceSubmitCancel={onCancel}
    />
  );
};

export default FormEncaminhamento;
