import React from 'react';

import Button from '@/components/Button';

import ArtifactFieldControl from './ArtifactFieldControl';
import { useArtifactForm } from './ArtifactForm.helpers';
import {
  ArtifactFormConfig,
  ResourceSubmitHandler,
} from './ArtifactForm.types';
import { ArtifactFieldLayout, Fieldset, Form, FormActions } from './styles';

interface Props<RType extends fhir4.DomainResource> {
  fields: ArtifactFormConfig<RType>;
  resource: RType;
  disabled?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  onResourceSubmit?: ResourceSubmitHandler<RType>;
  onResourceSubmitCancel?: ResourceSubmitHandler<RType>;
}

export interface ArtifactFormComponentProps<
  RType extends fhir4.DomainResource
> {
  resource: RType;
  disabled?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  onSubmit: ResourceSubmitHandler<RType>;
  onCancel: ResourceSubmitHandler<RType>;
}

function ArtifactForm<RType extends fhir4.DomainResource>({
  fields,
  resource,
  loading = false,
  disabled = false,
  readOnly = false,
  onResourceSubmit,
  onResourceSubmitCancel,
}: Props<RType>): React.ReactElement<Props<RType>> {
  const {
    formState: { errors },
    reset,
    control,
    handleSubmit,
  } = useArtifactForm(resource, fields);

  const onCancel = React.useCallback(() => {
    reset();
    if (onResourceSubmitCancel) {
      onResourceSubmitCancel(resource);
    }
  }, [onResourceSubmitCancel, reset, resource]);

  return (
    <Form
      onSubmit={onResourceSubmit && handleSubmit(onResourceSubmit)}
      onClick={evt => evt.stopPropagation()}
    >
      <Fieldset>
        {fields.map((field, index) => {
          return (
            <ArtifactFieldLayout type={field.type} key={field.name}>
              <ArtifactFieldControl
                control={control}
                config={{
                  ...field,
                  readonly: readOnly,
                  autoFocus: index === 0,
                }}
                error={errors[field.name]?.message}
                disabled={loading || disabled}
              />
            </ArtifactFieldLayout>
          );
        })}
      </Fieldset>
      {!readOnly && (
        <FormActions>
          <Button
            type="button"
            theme="secondary"
            onClick={onCancel}
            disabled={loading}
          >
            Cancelar
          </Button>

          <Button
            theme="primary"
            type="submit"
            loading={loading}
            disabled={loading || disabled}
          >
            Confirmar
          </Button>
        </FormActions>
      )}
    </Form>
  );
}

export default ArtifactForm;
