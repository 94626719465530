/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { enforceElement, RenderableElement } from '@/utils/elements';
import Tooltip from '../Tooltip';
import { Container, ButtonContainer } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: RenderableElement;
  tooltip?: string;
  style?: React.CSSProperties;
}

const IconButton: React.FC<Props> = ({
  icon,

  tooltip,
  style,
  ...buttonProps
}) => {
  return (
    <Container style={style}>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <ButtonContainer {...buttonProps}>
            {enforceElement(icon)}
          </ButtonContainer>
        </Tooltip>
      ) : (
        <ButtonContainer>{enforceElement(icon)}</ButtonContainer>
      )}
    </Container>
  );
};

export default IconButton;
