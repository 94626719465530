/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { TabsContext } from '..';
import { TabLabel } from './styles';

export interface TabProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  value: React.Key;
}

const Tab: React.FC<TabProps> = ({
  value,
  children,

  ...buttonProps
}) => {
  const { value: tabsValue, setValue } = React.useContext(TabsContext);

  return (
    <TabLabel
      active={value === tabsValue}
      onClick={() => setValue(value)}
      {...buttonProps}
    >
      {children}
    </TabLabel>
  );
};

export default Tab;
