import colors from '@/styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const DefaultList = styled.div`
  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
`;

export const ItemList = styled.div`
  width: 100%;
`;

export const SectionTitle = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 14px;
  height: 40px;

  img {
    margin-right: 20px;
  }

  h2 {
    color: ${colors.black};
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`;
