import styled, { css } from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';

export const Option = styled.li`
  list-style: none;
`;

interface LinkItemProps {
  disabled: boolean;
}

export const LinkItem = styled.a<LinkItemProps>`
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;

  transition: background-color 100ms ease-in;

  padding: 8px 16px;

  /* Label */
  ${HeadingSmall(14)}
  line-height: 24px;
  color: ${colors.blackAlpha100};

  ${props =>
    props.disabled
      ? css`
          /* Disabled */
          color: ${colors.gray50};
          pointer-events: none;
        `
      : css`
          /* Indie */
          cursor: pointer;

          /* Active */
          :hover,
          :focus {
            background-color: ${colors.gray50};
          }
        `}
`;
