import styled from 'styled-components';

import colors from '@/styles/colors';

export const Container = styled.div`
  background: #fff;
  border: 1px solid ${colors.secondaryLite};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  min-width: 400px;

  position: absolute;
  top: 31px;
  left: 0;
`;

export const Header = styled.div`
  padding: 20px 25px;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: #2f3139;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const HeaderFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Atualizacao = styled.div``;

export const AtualizacaoText = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
`;

export const AtualizacaoDate = styled.span`
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: ${colors.hardGray};
`;

export const Body = styled.div`
  padding: 20px 25px;
  max-height: 65vh;
  overflow-y: auto;
  border-top: 1px solid ${colors.secondaryLite};
`;

export const AutorCard = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const AutorTitle = styled.h3`
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
`;

export const AutorInfo = styled.div`
  display: flex;
  align-items: flex-start;

  img,
  span {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    object-fit: cover;
  }
`;

export const AutorDetail = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: ${props => (props.alignCenter ? 'center' : 'auto')};
`;

export const AutorDetailItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const AutorDetailItemTitle = styled.h4`
  font-size: 14px;
  margin-bottom: 2px;
`;

export const AutorDetailItemSubtitle = styled.p`
  font-size: 14px;
  color: ${colors.hardGray};
`;

export const SectionTitle = styled(AutorTitle)`
  margin-top: 10px;
`;
