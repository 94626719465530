import Button from '@/components/Button';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import ExecucaoAPI from '@/features/execucao/services/ExecucaoAPI';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { TagEmpresa } from '@/models/Tags';

import { Container, Form, FormInput, Header, Footer, Divider } from './styles';

interface IdentificadorProtocoloEnterpriseProps {
  tags?: TagEmpresa[];
  callbackIniciar: () => void;
}

interface FormData {
  [key: string]: string;
}

const IdentificadorProtocoloEnterprise = React.forwardRef<
  HTMLDivElement,
  IdentificadorProtocoloEnterpriseProps
>(({ tags, callbackIniciar }, ref) => {
  const [
    { protocoloExecutado },
    { dispatchSolicitarSaida },
  ] = useExecucaoContext();

  const [loading, setLoading] = useState(false);

  const [disabledForm, setDisabledForm] = useState(false);

  const { handleSubmit, register, control, reset } = useForm<FormData>({});

  const watchForm = useWatch({
    control,
    name: tags?.map(a => a.nome) || [],
  });

  const disableSubmit = useMemo(() => {
    return Object.values(watchForm).some(value => !value || value === '');
  }, [watchForm]);

  const handleSair = useCallback(() => {
    dispatchSolicitarSaida({});
  }, [dispatchSolicitarSaida]);

  const handleIniciar = useCallback(
    async (data: FormData) => {
      if (protocoloExecutado) {
        setLoading(true);
        try {
          await ExecucaoAPI.setProcoloExecutadoTags({
            protocoloExecutadoId: protocoloExecutado.id,
            tags: Object.entries(data).map(tag => ({
              nome: tag[0],
              valor: tag[1],
            })),
          });

          setDisabledForm(true);
          callbackIniciar();
        } catch (error) {
          //
        } finally {
          setLoading(false);
        }
      }
    },
    [protocoloExecutado, callbackIniciar],
  );

  useLayoutEffect(() => {
    if (protocoloExecutado?.tags.length) {
      setDisabledForm(true);
      reset(
        protocoloExecutado?.tags.reduce(
          (a, v) => ({ ...a, [v.nome]: v.valor }),
          {},
        ),
      );
    }
  }, [protocoloExecutado, reset]);

  return (
    <Container ref={ref}>
      <Header>Por favor, adicione o código de atendimento para iniciar.</Header>
      <Form onSubmit={handleSubmit(handleIniciar)}>
        {tags?.map((tagEmpresa, index) => (
          <FormInput
            id={tagEmpresa.nome}
            key={tagEmpresa.nome}
            ref={register}
            name={tagEmpresa.nome}
            title={tagEmpresa.descricao}
            disabled={disabledForm}
            autoComplete="off"
            autoFocus={index === 0}
            required
          />
        ))}
        {!disabledForm && (
          <>
            <Divider />
            <Footer>
              <Button
                type="button"
                theme="secondary"
                disabled={loading}
                onClick={handleSair}
              >
                Sair do protocolo
              </Button>
              <Button
                type="submit"
                disabled={loading || disableSubmit}
                loading={loading}
              >
                Iniciar atendimento
              </Button>
            </Footer>
          </>
        )}
      </Form>
    </Container>
  );
});

export default IdentificadorProtocoloEnterprise;
